import React from "react";
import { useSelector } from "react-redux";
import { InfoOutlined } from "@mui/icons-material";

import Tooltip from "../../components/Tooltip";
import messages from "../../assets/locale/messages";
import RequestsTable from "../../components/RequestsTable";

const Requests = () => {
  const lang = useSelector((state) => state.locale.lang);
  const { requests, shared } = messages[lang];

  const headLines = [
    shared.image,
    requests.listRequests.applicantName,
    requests.listRequests.eventName,
    requests.listRequests.cityOfResidence,
    shared.gender,
    requests.listRequests.note,
    <Tooltip
      key={1}
      title={<p>{shared.hint}</p>}
      content={
        <span>
          {shared.role}
          <span className="px-1">
            <InfoOutlined fontSize="small" />
          </span>
        </span>
      }
    />,
    requests.listRequests.rating,
    requests.listRequests.nationality,
    shared.status,
    shared.actions,
  ];

  const breadcrumbsList = [
    {
      path: "",
      label: requests.listRequests.list,
    },
  ];
  return (
    <RequestsTable
      headlines={headLines}
      breadcrumbsList={breadcrumbsList}
      searchPlaceholder={requests.listRequests.search}
    />
  );
};

export default Requests;
