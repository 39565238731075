import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Add } from "@mui/icons-material";

import RadioButtonsGroup from "../../../../components/RadioButton";
import messages from "../../../../assets/locale/messages";
import { getLookupsRequest } from "../../../../store/Lookups/actions";
import { getRequestsRequest } from "../../../../store/Requests/actions";
import FilterSection from "../../../../components/Filter";
import EmptyState from "../../../../components/EmptyState";
import applicantsPlaceholder from "../../../../assets/images/event-screen/applicants-placeholder.svg";
import inviteApplicantsPlaceholder from "../../../../assets/images/event-screen/invite-applicants-placeholder.svg";
import {
  eventStatuses,
  genders,
  lookupsKeys,
} from "../../../../utils/Constants";
import Supervisor from "./Supervisor";
import Others from "./Others";
import { textContainOnlySpaces } from "../../../../utils/Helpers";
import { ROUTE_PATHS } from "../../../../utils/RoutesPaths";
import Button from "../../../../components/Button";
import {
  deleteLocationRequest,
  getLocationsRequest,
} from "../../../../store/Locations/actions";
import {
  createNoteRequest,
  deleteNoteRequest,
  getNoteRequest,
  updateNoteRequest,
} from "../../../../store/Notes/action";
import Modal from "../../../../components/Modal";
import Note from "../../../../components/Note";
import ExportForm from "./ExportModal";
import { downloadDataRequest } from "../../../../store/Download/action";

const ApplicantsTab = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();

  const lang = useSelector((state) => state.locale.lang);
  const list = useSelector((state) => state.requests.applicantsTab.list);
  const event = useSelector((state) => state.events.event);
  const isLoading = useSelector((state) => state.loader);
  const isAuth = useSelector((state) => state.auth.login?.auth_token);
  const { events, shared } = messages[lang];
  const [searchValue, setSearchValue] = useState("");
  const [filterStatus, setFilterStatus] = useState(null);
  const [filterGender, setFilterGender] = useState(null);
  const [filterRoles, setFilterRoles] = useState(null);
  const [filterZone, setFilterZone] = useState(null);
  const [filterCheckinStatus, setFilterCheckinStatus] = useState(null);
  const [roles, setRoles] = useState([]);
  const [page, setPage] = useState(1);
  const [isSupervisor, setIsSupervisor] = useState(true);
  const [isUnableDoingAction, setIsUnableDoingAction] = useState(null);
  const [selectValue, setSelectValue] = useState(null);
  const [otherValue, setOtherValue] = useState("");
  const [validate, setValidate] = useState(false);
  const [selectedApplicantId, setSelectedApplicantId] = useState(null);
  const [isNoteModalOpen, setIsNoteModalOpen] = useState(false);
  const [csvFile] = useState(true);
  const [isModal, setIsModal] = useState(false);
  const [selectedFields, setSelectedFields] = useState({
    imageUrl: false,
    name: false,
    email: false,
    gender: false,
    note: false,
    zone: false,
    checkInStatus: false,
    status: false,
    stcNumber: false,
    applicantID: false,
    phoneNumber: false,
    dailyAmount: false,
    totalAmount: false,
    numberOfWorking: false,
    role: false,
    supervisor: false,
  });
  const handleClose = () => {
    setIsModal(false);
    setSelectedFields({
      imageUrl: false,
      name: false,
      email: false,
      gender: false,
      note: false,
      zone: false,
      checkInStatus: false,
      status: false,
      stcNumber: false,
      applicantID: false,
      phoneNumber: false,
      dailyAmount: false,
      totalAmount: false,
      numberOfWorking: false,
      role: false,
      supervisor: false,
    });
  };

  const handleChange = (event) => {
    const { name, checked } = event.target;
    setSelectedFields((prevFields) => ({
      ...prevFields,
      [name]: checked,
    }));
  };

  const location = useSelector((state) => state.locations.list);
  const note = useSelector((state) => state.note.list);
  const handleAddNote = (applicantId) => {
    setSelectedApplicantId(applicantId);
    setIsNoteModalOpen(true);
    setSelectValue(shared.selectedLocation);
    setOtherValue("");
    dispatch(getLocationsRequest());
  };
  const handleModalClose = () => setIsNoteModalOpen(false);
  const handleDeleteNote = (applicantId) => {
    dispatch(deleteNoteRequest({ id: applicantId }));
    getApplicantsList(
      page,
      roles,
      filterRoles,
      filterCheckinStatus,
      isSupervisor,
      filterZone,
      filterGender
    );
  };
  const handleEditNote = (applicantId) => {
    dispatch(getNoteRequest({ id: applicantId }));
    setSelectedApplicantId(applicantId);
    setIsNoteModalOpen(true);
  };
  const handleDeleteLocation = (_id) => {
    dispatch(deleteLocationRequest({ id: _id }));
    setIsNoteModalOpen(false);
    history.push(ROUTE_PATHS["viewEvent"].replace(":id", id));
  };
  useEffect(() => {
    const _location = location.find((item) => item.id === note.location_id);
    setSelectValue(
      note.custom_location
        ? shared.other
        : _location?.name || shared.selectedLocation
    );
    setOtherValue(note.custom_location || "");
  }, [note, dispatch]);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!selectValue) return;
    const location_id = location.find((item) => item.name === selectValue);
    handleModalClose();
    setSelectValue(null);
    setOtherValue("");
    dispatch(
      note?.custom_location
        ? updateNoteRequest({
            id: selectedApplicantId,
            data: {
              custom_location: otherValue,
              location_id: location_id?.id,
            },
          })
        : createNoteRequest({
            id: selectedApplicantId,
            data: {
              custom_location: otherValue,
              location_id: location_id?.id,
            },
          })
    );
  };
  const handleExport = () => {
    setIsModal(true);
  };

  const checkinStatusOptions = [
    {
      id: 1,
      name: events.viewEvents.checkedIn,
      value: "checked_in",
    },
    {
      id: 2,
      name: events.viewEvents.nonCheckedIn,
      value: "not_checked_in",
    },
  ];
  const statusOptions = [
    {
      id: 1,
      name: events.viewEvents.applicantsTab.assigned,
      value: true,
    },
    {
      id: 2,
      name: events.viewEvents.applicantsTab.notAssigned,
      value: false,
    },
  ];
  const genderOptions = [
    {
      id: 1,
      name: shared.male,
      value: genders.male,
    },
    {
      id: 2,
      name: shared.female,
      value: genders.female,
    },
    {
      id: 3,
      name: shared.notProvided,
      value: genders.notProvided,
    },
  ];

  useEffect(() => {
    dispatch(getLookupsRequest([lookupsKeys.roles]));
  }, [lang]);
  useEffect(() => {
    dispatch(getLocationsRequest());
  }, [dispatch, lang, note]);

  useEffect(() => {
    setIsUnableDoingAction(
      event?.status == "past" || event?.status == "canceled"
    );
  }, [id]);

  useEffect(() => {
    if (filterStatus) {
      const localizedFilterValue = statusOptions.find(
        (option) => option.id === filterStatus.id
      );
      setFilterStatus(localizedFilterValue);
    }
  }, [lang]);

  useEffect(() => {
    if (filterCheckinStatus) {
      const localizedFilterValue = checkinStatusOptions.find(
        (option) => option.id === filterCheckinStatus.id
      );
      setFilterCheckinStatus(localizedFilterValue);
    }
  }, [lang]);

  useEffect(() => {
    if (!event?.event_roles?.length) return;
    const mappingRoles = event?.event_roles
      .map((role) => role.role)
      .filter((role) => role.name != "Supervisor");
    setRoles(mappingRoles);
  }, [!!event?.event_roles?.length]);

  useEffect(() => {
    if (filterGender) {
      const localizedFilterValue = genderOptions.find(
        (option) => option.id === filterGender.id
      );
      setFilterGender(localizedFilterValue);
    }
  }, [lang]);

  const listofUsers = [
    {
      value: true,
      label: events.viewEvents.applicantsTab.supervisor,
    },
    {
      value: false,
      label: events.viewEvents.applicantsTab.others,
    },
  ];

  const getApplicantsList = (
    pageNo,
    search,
    status,
    gender,
    isSupervisor,
    zone,
    filterRoles,
    filterCheckin
  ) => {
    dispatch(
      getRequestsRequest({
        page: pageNo,
        items: 10,
        event_id: id,
        [isSupervisor ? "user_name" : "query"]: search,
        is_assigned_to_zone: status?.value,
        gender: gender?.value,
        is_supervisor: isSupervisor,
        zone_id: zone?.id,
        role_id: filterRoles?.id,
        order_by_created_at: "asc",
        status: "approved",
        responseType: "applicantsTab",
        flag_is_user_checkedin: [
          eventStatuses.ongoing,
          eventStatuses.past,
        ].includes(event?.status)
          ? id
          : null,
        is_checked_in: filterCheckin?.value,
      })
    );
  };

  useEffect(() => {
    if (
      (searchValue.length >= 3 || searchValue.length === 0) &&
      !textContainOnlySpaces(searchValue)
    ) {
      setPage(1);
      getApplicantsList(
        1,
        searchValue,
        filterStatus,
        filterGender,
        isSupervisor,
        filterZone,
        filterRoles,
        filterCheckinStatus
      );
    }
  }, [searchValue]);

  useEffect(() => {
    setPage(1);
    getApplicantsList(
      1,
      searchValue,
      filterStatus,
      filterGender,
      isSupervisor,
      filterZone,
      filterRoles,
      filterCheckinStatus
    );
  }, [
    filterStatus,
    filterGender,
    filterZone,
    filterRoles,
    filterCheckinStatus,
  ]);

  useEffect(() => {
    setFilterRoles(null);
    setPage(1);
    getApplicantsList(
      1,
      searchValue,
      filterStatus,
      filterGender,
      isSupervisor,
      filterZone,
      null,
      filterCheckinStatus
    );
  }, [isSupervisor]);

  useEffect(() => {
    getApplicantsList(
      page,
      searchValue,
      filterStatus,
      filterGender,
      isSupervisor,
      filterZone,
      filterRoles,
      filterCheckinStatus
    );
  }, [page, lang]);

  useEffect(() => {
    getApplicantsList(
      page,
      searchValue,
      filterStatus,
      filterGender,
      isSupervisor,
      filterZone,
      filterRoles,
      filterCheckinStatus
    );
  }, [note, lang]);

  const setPageHandle = (page) => {
    setPage(page);
  };

  const handleSubmitData = async (e) => {
    e.preventDefault();
    const searchParams = new URLSearchParams({
      locale: lang,
      page,
      items: 10,
      event_id: id,
      is_supervisor: isSupervisor,
      status: filterStatus === null ? "approved" : filterCheckinStatus.value,
      order_by_created_at: "asc",
      is_checked_in:
        filterCheckinStatus === null ? " " : filterCheckinStatus.value,
      columns: [
        selectedFields.applicantID ? "ID" : "",
        selectedFields.imageUrl ? "Image URL" : "",
        selectedFields.name ? "Name" : "",
        selectedFields.email ? "Email" : "",
        selectedFields.gender ? "Gender" : "",
        selectedFields.note ? "Note" : "",
        selectedFields.zone ? "Zone" : "",
        selectedFields.checkInStatus ? "Check-in Status" : "",
        selectedFields.status ? "Status" : "",
        selectedFields.stcNumber ? "Stc Number" : "",
        selectedFields.phoneNumber ? "Phone Number" : "",
        selectedFields.dailyAmount ? "Daily Amount" : "",
        selectedFields.totalAmount ? "Total Amount" : "",
        selectedFields.numberOfWorking ? "Number of Working Days" : "",
        selectedFields.role ? "Role" : "",
        selectedFields.supervisor ? "Supervisor" : "",
      ]
        .filter(Boolean)
        .join(","),
    });
    const params = Object.fromEntries(searchParams.entries());
    dispatch(downloadDataRequest({ params, token: isAuth }));
    handleClose();
  };

  const renderEmptyState = () => {
    return (
      <>
        <div className="bg-white rounded-4 mt-4 py-4">
          <EmptyState
            description={
              searchValue ||
              filterStatus ||
              filterGender ||
              filterRoles ||
              filterZone ||
              filterCheckinStatus
                ? events.viewEvents.applicantsTab.placeholders.noSearchResults
                : events.viewEvents.applicantsTab.placeholders.noData
            }
            imgSrc={
              event?.is_private
                ? inviteApplicantsPlaceholder
                : applicantsPlaceholder
            }
            imgClass={event?.is_private ? "decreased-img-size" : ""}
            showActionButton={event.is_private}
            buttonLabel={events.viewEvents.applicantsTab.inviteMoreApplicants}
            handleCreate={() =>
              history.push(
                ROUTE_PATHS.inviteApplicants.replace(":id", event.id)
              )
            }
            actionBtnDisabled={
              ![eventStatuses.ongoing, eventStatuses.upcoming].includes(
                event?.status
              )
            }
          />
        </div>
      </>
    );
  };
  return (
    <div className="conatiner requests-list-container">
      <div className="row mb-3">
        <FilterSection
          csvFile={csvFile}
          csvBtnName={events.viewEvents.applicantsTab.export}
          evenStatus={event?.status}
          handleExport={handleExport}
          renderActionNextToSearch={
            event?.is_private &&
            [eventStatuses.ongoing, eventStatuses.upcoming].includes(
              event?.status
            ) &&
            list.length ? (
              <Button
                label={
                  <>
                    <Add />
                    <span>{events.viewEvents.applicantsTab.invite}</span>
                  </>
                }
                className={lang === "en" ? "ms-3" : "me-3"}
                onClick={() =>
                  history.push(
                    ROUTE_PATHS.inviteApplicants.replace(":id", event.id)
                  )
                }
              />
            ) : null
          }
          searchInput={{
            placeholder: !isSupervisor
              ? events.viewEvents.applicantsTab.placeholders.others
              : events.viewEvents.applicantsTab.placeholders.supervisor,
            name: "search",
            value: searchValue,
            onChange: (value) => setSearchValue(value),
          }}
          filters={[
            {
              type: "filter",
              name: "status",
              placeholder: events.viewEvents.applicantsTab.status,
              value: filterStatus,
              options: statusOptions,
              onChange: (value) => setFilterStatus(value),
            },
            {
              type: "filter",
              name: "gender",
              placeholder: events.viewEvents.applicantsTab.gender,
              value: filterGender,
              options: genderOptions,
              onChange: (value) => setFilterGender(value),
            },
            {
              type: "filter",
              name: "zone",
              placeholder: events.viewEvents.applicantsTab.zone,
              value: filterZone,
              options: event?.zones,
              onChange: (value) => setFilterZone(value),
            },
            [eventStatuses.ongoing, eventStatuses.past].includes(event?.status)
              ? {
                  type: "filter",
                  name: "checkin-status",
                  placeholder: events.viewEvents.checkinStatus,
                  value: filterCheckinStatus,
                  options: checkinStatusOptions,
                  onChange: (value) => setFilterCheckinStatus(value),
                }
              : null,
            !isSupervisor
              ? {
                  type: "filter",
                  name: "role",
                  placeholder: events.viewEvents.applicantsTab.role,
                  value: filterRoles,
                  options: roles,
                  onChange: (value) => setFilterRoles(value),
                }
              : null,
          ]}
        />
        <div className="d-flex justify-content-between flex-md-row flex-column">
          <div>
            <p className="fweight-600 roles-label mb-0">
              {events.viewEvents.roles}:
            </p>
            <div className="col-12 d-flex align-items-center applicants-type-select">
              <RadioButtonsGroup
                wrapperClassName="d-flex align-items-center flex-row"
                labelClassName="d-none"
                className="d-flex align-items-start flex-row flex-nowrap mx-3 my-2 "
                required
                handleChange={(e) => {
                  setIsSupervisor(e.target.value === "true");
                }}
                value={isSupervisor}
                listOfRadios={listofUsers}
                hideStar
              />
            </div>
          </div>
          {event?.status === eventStatuses.ongoing && (
            <div className="d-flex">
              <div className={`${lang === "en" ? "me-3" : "ms-3"}`}>
                <p className="count fweight-500 fsize-16 mb-2 text-primary-blue">
                  {event?.number_of_checked_in_users}
                </p>
                <p className="label text-grey mb-0">
                  {events.viewEvents.checkedinApplicants}
                </p>
              </div>
              <div>
                <p className="count fweight-500 fsize-16 mb-2 text-primary-blue">
                  {event?.number_of_non_checked_in_users}
                </p>
                <p className="label text-grey mb-0">
                  {events.viewEvents.noncheckedinApplicants}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="d-flex flex-column justify-content-between">
        {!isLoading && !list?.length ? (
          renderEmptyState()
        ) : !isSupervisor ? (
          <Others
            setPageHandle={setPageHandle}
            lists={list}
            eventId={id}
            searchValue={searchValue}
            isUnableDoingAction={isUnableDoingAction}
          />
        ) : (
          <Supervisor
            setPageHandle={setPageHandle}
            lists={list}
            handleAddNote={(id) => handleAddNote(id)}
            handleEditNote={(id) => handleEditNote(id)}
            handleDeleteNote={(id) => handleDeleteNote(id)}
            eventId={id}
            searchValue={searchValue}
            isUnableDoingAction={isUnableDoingAction}
          />
        )}
        <Modal
          open={isNoteModalOpen}
          title={shared.addYourNote}
          handleClose={handleModalClose}
          cancelBtnTxt={shared.cancel}
          handleCancel={handleModalClose}
          confirmBtnTxt={shared.submit}
          handleConfirm={handleSubmit}
          isDisabled={validate}
          content={
            <Note
              validate={validate}
              setValidate={setValidate}
              handleDelete={(id) => handleDeleteLocation(id)}
              handleSubmit={handleSubmit}
              setOtherValue={setOtherValue}
              otherValue={otherValue}
              selectValue={selectValue}
              setSelectValue={setSelectValue}
              location={location}
            />
          }
        />

        <Modal
          open={isModal}
          title={events.viewEvents.applicantsTab.selectFile}
          handleClose={handleClose}
          cancelBtnTxt={shared.cancel}
          handleCancel={handleClose}
          confirmBtnTxt={shared.confirm}
          handleConfirm={handleSubmitData}
          content={
            <ExportForm
              isSupervisor={isSupervisor}
              handleSubmit={handleSubmitData}
              selectedFields={selectedFields}
              handleChange={handleChange}
            />
          }
        />
      </div>
    </div>
  );
};

export default ApplicantsTab;
