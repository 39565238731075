import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Block } from "@mui/icons-material";

import { getApplicantsRequest } from "../../store/applicants/actions";
import {
  getCitiesRequest,
  getLookupsRequest,
} from "../../store/Lookups/actions";
import messages from "../../assets/locale/messages";
import { ROUTE_PATHS } from "../../utils/RoutesPaths";
import {
  applicantsProfileStatus,
  genders,
  lookupsKeys,
} from "../../utils/Constants";
import { textContainOnlySpaces } from "../../utils/Helpers";
import Pagination from "../../components/Pagination";
import Rate from "../../components/Rate";
import Filters from "../../components/Filter";
import Breadcrumbs from "../../components/BreadCrumbs";
import EmptyState from "../../components/EmptyState";
import DynamicTable from "../../components/DynamicTable";
import applicantPlaceholder from "../../assets/images/applicant-screen/applicants-placeholder.svg";
import Modal from "../../components/Modal";
import Avatar from "../../components/Avatar";
import "./Applicants.scss";
import Note from "../../components/Note";
import {
  createNoteRequest,
  deleteNoteRequest,
  getNoteRequest,
  updateNoteRequest,
} from "../../store/Notes/action";
import {
  deleteLocationRequest,
  getLocationsRequest,
} from "../../store/Locations/actions";
import Button from "../../components/Button";

const renderColumns = (
  applicants,
  shared,
  handleAddNote,
  handleEditNote,
  handleDeleteNote,
  handleEditProfile
) => {
  return [
    {
      field: "id",
      headerName: shared.id,
      editable: false,
      filterable: false,
      hideable: false,
      hideSortIcons: true,
      sortable: false,
      width: 105,
      renderCell: (params) => {
        return (
          <div className="MuiDataGrid-cellContent">
            {params.row?.id ? <span>{params.row.id}</span> : "-"}
          </div>
        );
      },
    },
    {
      field: "img",
      headerName: shared.image,
      editable: false,
      filterable: false,
      hideable: false,
      hideSortIcons: true,
      sortable: false,
      width: 70,
      renderCell: (params) => {
        return (
          <div className={`MuiDataGrid-cellContent`}>
            {params.row?.name && (
              <Avatar
                imgSrcAlt="employee img"
                imgSrc={params.row?.img}
                name={params?.value}
                size={30}
              ></Avatar>
            )}
          </div>
        );
      },
    },
    {
      field: "name",
      headerName: applicants.listApplicants.name,
      editable: false,
      filterable: false,
      hideable: false,
      hideSortIcons: true,
      sortable: false,
      renderCell: (params) => {
        return (
          <div className={`MuiDataGrid-cellContent d-flex align-items-center`}>
            <div className="user-name">
              {params.value.data}
              {params.value.isBlocked && (
                <Block color="error" className="mx-2" fontSize="small" />
              )}
            </div>
          </div>
        );
      },
    },
    {
      field: "email",
      headerName: applicants.listApplicants.email,
      editable: false,
      filterable: false,
      hideable: false,
      hideSortIcons: true,
      sortable: false,
    },
    {
      field: "role",
      headerName: applicants.listApplicants.role,
      editable: false,
      filterable: false,
      hideable: true,
      hideSortIcons: true,
      sortable: false,
    },
    {
      field: "note",
      headerName: applicants.listApplicants.note,
      editable: false,
      filterable: false,
      hideable: true,
      hideSortIcons: true,
      sortable: false,
    },
    {
      field: "nationality",
      headerName: applicants.listApplicants.nationality,
      editable: false,
      filterable: false,
      hideable: true,
      hideSortIcons: true,
      sortable: false,
    },
    {
      field: "city",
      headerName: applicants.listApplicants.cityOfResidence,
      editable: false,
      filterable: false,
      hideable: true,
      hideSortIcons: true,
      sortable: false,
    },
    {
      field: "fieldsOfStudy",
      headerName: applicants.listApplicants.studyField,
      editable: false,
      filterable: false,
      hideable: true,
      hideSortIcons: true,
      sortable: false,
    },
    {
      field: "profileRate",
      headerName: applicants.listApplicants.profileRate,
      editable: false,
      filterable: false,
      hideable: true,
      hideSortIcons: true,
      sortable: false,
      renderCell: (params) =>
        params.row.profileRate ? (
          <div className="MuiDataGrid-cellContent">
            <Rate rating={params.value.toFixed(2)} />
          </div>
        ) : (
          "-"
        ),
    },
    {
      field: "age",
      headerName: applicants.listApplicants.age,
      hide: true,
      editable: false,
      filterable: false,
      hideSortIcons: true,
      sortable: false,
    },
    {
      field: "language",
      headerName: applicants.listApplicants.language,
      editable: false,
      hide: true,
      filterable: false,
      hideable: true,
      hideSortIcons: true,
      sortable: false,
      renderCell: (params) =>
        params.row.language.length ? (
          <div className="MuiDataGrid-cellContent">
            {params.row.language.map((lang, i) => (
              <span key={i}>
                <span>{lang.language}</span>
                {i < params.row.language.length - 1 && <span>, </span>}
              </span>
            ))}
          </div>
        ) : (
          "-"
        ),
    },
    {
      field: "gender",
      headerName: applicants.listApplicants.gender,
      editable: false,
      hide: true,
      filterable: false,
      hideable: true,
      hideSortIcons: true,
      sortable: false,
    },
    {
      field: "weight",
      headerName: applicants.listApplicants.weight,
      editable: false,
      hide: true,
      filterable: false,
      hideable: true,
      hideSortIcons: true,
      sortable: false,
    },
    {
      field: "height",
      headerName: applicants.listApplicants.height,
      editable: false,
      hide: true,
      filterable: false,
      hideable: true,
      hideSortIcons: true,
      sortable: false,
    },
    {
      field: "profileStatus",
      headerName: applicants.listApplicants.profileStatus,
      editable: false,
      hide: true,
      filterable: false,
      hideable: true,
      hideSortIcons: true,
      sortable: false,
    },
    {
      field: "applicantStatus",
      headerName: applicants.listApplicants.applicantStatus,
      editable: false,
      hide: true,
      filterable: false,
      hideable: true,
      hideSortIcons: true,
      sortable: false,
    },
    {
      field: "disability",
      headerName: applicants.listApplicants.disability,
      editable: false,
      hide: true,
      filterable: false,
      hideable: true,
      hideSortIcons: true,
      sortable: false,
      renderCell: (params) =>
        params.row.disability.data.length ? (
          <div className="MuiDataGrid-cellContent">
            {params.row.disability.data.map((disability, i) => (
              <span key={i}>
                <span>{disability.name}</span>
                {i < params.row.disability.data.length - 1 && <span>, </span>}
              </span>
            ))}
          </div>
        ) : (
          <span>
            {params.row.disability.status === "verified"
              ? "-"
              : applicants.listApplicants.noDisability}
          </span>
        ),
    },
    {
      field: "diseases",
      headerName: applicants.listApplicants.diseases,
      editable: false,
      hide: true,
      filterable: false,
      hideable: true,
      hideSortIcons: true,
      sortable: false,
      renderCell: (params) =>
        params.row.diseases.data.length ? (
          <div className="MuiDataGrid-cellContent">
            {params.row.diseases.data.map((diseases, i) => (
              <span key={i}>
                <span>{diseases.name}</span>
                {i < params.row.diseases.data.length - 1 && <span>, </span>}
              </span>
            ))}
          </div>
        ) : (
          <span>
            {params.row.diseases.status === "verified"
              ? "-"
              : applicants.listApplicants.noDisease}
          </span>
        ),
    },
    {
      field: "Actions",
      headerName: applicants.applicantRequsets.actions,
      editable: false,
      width: 280,
      filterable: false,
      hideable: true,
      hideSortIcons: true,
      sortable: false,
      renderCell: (params) => {
        const noteContentExists = params.row.note && params.row.note !== "-";
        return (
          <div className="d-flex gap-1 w-100">
            {noteContentExists ? (
              <>
                <Button
                  label={shared.editNote}
                  className="MuiDataGrid-cellContent"
                  small={true}
                  onClick={(e) => handleEditNote(params.row.id, e)}
                />
                <Button
                  className={"MuiDataGrid-cellContent"}
                  negative={true}
                  outlined={true}
                  label={shared.deleteNote}
                  small={true}
                  onClick={(e) => handleDeleteNote(params.row.id, e)}
                />
              </>
            ) : (
              <Button
                className={"MuiDataGrid-cellContent"}
                label={shared.addNote}
                small={true}
                onClick={(e) => handleAddNote(params.row.id, e)}
              />
            )}
            <Button
              className={"MuiDataGrid-cellContent"}
              small={true}
              label={shared.editProfile}
              onClick={(e) => handleEditProfile(params.row.id, e)}
            />
          </div>
        );
      },
    },
  ];
};

const Applicants = () => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.locale.lang);
  const history = useHistory();
  const { applicants, shared } = messages[lang];
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [rows, setRows] = useState([]);
  const list = useSelector((state) => state.applicants.list);
  const meta = useSelector((state) => state.applicants.meta);
  const isLoading = useSelector((state) => state.loader);
  const roles = useSelector((state) => state.lookups.general.roles);
  const languages = useSelector((state) => state.lookups.general.languages);
  const disabilities = useSelector(
    (state) => state.lookups.general.disabilities
  );
  const chronicDiseases = useSelector(
    (state) => state.lookups.general.chronicDiseases
  );
  const cities = useSelector((state) => state.lookups.cities);

  const [filterProfileStatus, setFilterProfileStatus] = useState(null);
  const [filterApplicantStatus, setFilterApplicantStatus] = useState(null);
  const [filterGender, setFilterGender] = useState(null);
  const [filterAge, setFilterAge] = useState(null);
  const [filterWeight, setFilterWeight] = useState(null);
  const [filterHeight, setFilterHeight] = useState(null);
  const [filterRole, setFilterRole] = useState(null);
  const [filterLanguages, setFilterLanguages] = useState(null);
  const [filterChronicDiseases, setFilterChronicDiseases] = useState(null);
  const [filterDisabilities, setFilterDisabilities] = useState(null);
  const [filterCities, setFilterCities] = useState(null);
  const [selectValue, setSelectValue] = useState(null);
  const [otherValue, setOtherValue] = useState("");
  const [validate, setValidate] = useState(false);
  const location = useSelector((state) => state.locations.list);
  const [selectedApplicantId, setSelectedApplicantId] = useState(null);
  const [isNoteModalOpen, setIsNoteModalOpen] = useState(false);
  const note = useSelector((state) => state.note.list);
  const profileStatusOptions = [
    {
      id: 1,
      name: applicants.listApplicants.verified,
      value: applicantsProfileStatus.verified,
    },
    {
      id: 2,
      name: applicants.listApplicants.profile_completed,
      value: applicantsProfileStatus.profileCompleted,
    },
    {
      id: 3,
      name: applicants.listApplicants.basic_profile_completed,
      value: applicantsProfileStatus.basicProfileCompleted,
    },
  ];
  const applicantStatusOptions = [
    { id: 1, name: applicants.listApplicants.assigned, value: true },
    { id: 2, name: applicants.listApplicants.notAssigned, value: false },
  ];
  const genderOptions = [
    {
      id: 1,
      name: applicants.listApplicants.notProvided,
      value: genders.notProvided,
    },
    { id: 2, name: applicants.listApplicants.male, value: genders.male },
    { id: 3, name: applicants.listApplicants.female, value: genders.female },
  ];
  const ageOptions = [
    { id: 1, name: applicants.listApplicants.ageRange1, value: [16, 25] },
    { id: 2, name: applicants.listApplicants.ageRange2, value: [25, 30] },
    { id: 3, name: applicants.listApplicants.ageRange3, value: [30, 35] },
    { id: 4, name: applicants.listApplicants.ageRange4, value: [35, 40] },
  ];
  const weightOptions = [
    { id: 1, name: applicants.listApplicants.weightRange1, value: [40, 50] },
    { id: 2, name: applicants.listApplicants.weightRange2, value: [50, 70] },
    { id: 3, name: applicants.listApplicants.weightRange3, value: [70, 100] },
    { id: 4, name: applicants.listApplicants.weightRange4, value: [100, 120] },
    { id: 5, name: applicants.listApplicants.weightRange5, value: [120, 170] },
  ];
  const heightOptions = [
    { id: 1, name: applicants.listApplicants.heightRange1, value: [140, 160] },
    { id: 2, name: applicants.listApplicants.heightRange2, value: [160, 180] },
    { id: 3, name: applicants.listApplicants.heightRange3, value: [180, 200] },
    { id: 4, name: applicants.listApplicants.heightRange4, value: [200, 300] },
  ];

  const handleEditNote = (applicantId, e) => {
    e.stopPropagation();
    setIsNoteModalOpen(true);
    setSelectedApplicantId(applicantId);
    dispatch(getNoteRequest({ id: applicantId }));
  };

  useEffect(() => {
    const _location = location.find((item) => item.id === note.location_id);
    setSelectValue(
      note.custom_location
        ? shared.other
        : _location?.name || shared.selectedLocation
    );
    setOtherValue(note.custom_location || "");
  }, [note, dispatch]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!selectValue) return;
    const location_id = location.find((item) => item.name === selectValue);
    handleModalClose();
    setSelectValue(shared.selectedLocation);
    setOtherValue("");
    dispatch(
      note?.custom_location
        ? updateNoteRequest({
            id: selectedApplicantId,
            data: {
              custom_location: otherValue,
              location_id: location_id?.id,
            },
          })
        : createNoteRequest({
            id: selectedApplicantId,
            data: {
              custom_location: otherValue,
              location_id: location_id?.id,
            },
          })
    );
  };
  const handleAddNote = (applicantId, e) => {
    e.stopPropagation();
    setSelectedApplicantId(applicantId);
    setIsNoteModalOpen(true);
    setSelectValue(shared.selectedLocation);
    setOtherValue("");
    dispatch(getLocationsRequest());
  };

  const handleModalClose = () => setIsNoteModalOpen(false);

  const handleDeleteNote = (applicantId, e) => {
    e.stopPropagation();
    setSelectedApplicantId(applicantId);
    dispatch(deleteNoteRequest({ id: applicantId }));
    getApplicants(
      page,
      searchValue,
      filterProfileStatus,
      filterApplicantStatus,
      filterGender,
      filterAge,
      filterWeight,
      filterHeight,
      filterRole,
      filterLanguages,
      filterChronicDiseases,
      filterDisabilities,
      filterCities
    );
  };
  const handleEditProfile = (id, e) => {
    e.stopPropagation();
    history.push(ROUTE_PATHS.editApplicantProfile.replace(":id", id));
  };
  const columns = useMemo(() => {
    return renderColumns(
      applicants,
      shared,
      handleAddNote,
      handleEditNote,
      handleDeleteNote,
      handleEditProfile
    );
  }, [applicants]);

  useEffect(() => {
    return () => {
      sessionStorage.removeItem("applicantsVisibleCols");
    };
  }, []);

  useEffect(() => {
    setRows(
      list.map((row) => ({
        id: row.id,
        name: {
          data:
            lang === "en" && row.full_name_en.trim()
              ? row.full_name_en
              : lang === "ar" && row.full_name_ar.trim()
              ? row.full_name_ar
              : "-",
          isBlocked: row.is_blocked,
        },
        email: row.email || "-",
        nationality: row.nationality?.name || "-",
        fieldsOfStudy: row.field_of_study?.name || "-",
        profileRate: row.rating,
        role: row.role?.name || "-",
        note: row.note?.custom_location || row.note?.location?.name || "-",
        city: row.city?.name || "-",
        age: row.age || "-",
        language: row.user_languages || [],
        gender: shared.genderOptions[row.gender] || "-",
        weight: row.weight ? `${row.weight} ${shared.kg}` : "-",
        height: row.height ? `${row.height} ${shared.cm}` : "-",
        profileStatus: applicants.listApplicants[row.profile_status] || "-",
        applicantStatus: row.participated_in_events_count
          ? applicants.listApplicants.assigned
          : applicants.listApplicants.notAssigned,
        disability: {
          data: row.disabilities || [],
          status: row.profile_status,
        },
        diseases: {
          data: row.chronic_diseases || [],
          status: row.profile_status,
        },
        profileStatusValue: row.profile_status,
        img: row.image,
      }))
    );
  }, [list]);
  useEffect(() => {
    dispatch(
      getLookupsRequest([
        lookupsKeys.disabilities,
        lookupsKeys.chronicDiseases,
        lookupsKeys.languages,
        lookupsKeys.roles,
      ])
    );
    dispatch(
      getCitiesRequest({
        page: -1,
        order_by_name_en: lang === "en" ? "asc" : null,
        order_by_name_ar: lang === "ar" ? "asc" : null,
      })
    );
    getApplicants(
      page,
      searchValue,
      filterProfileStatus,
      filterApplicantStatus,
      filterGender,
      filterAge,
      filterWeight,
      filterHeight,
      filterRole,
      filterLanguages,
      filterChronicDiseases,
      filterDisabilities,
      filterCities
    );

    if (filterProfileStatus) {
      const localizedFilterValue = profileStatusOptions.find(
        (option) => option.id === filterProfileStatus.id
      );
      setFilterProfileStatus(localizedFilterValue);
    }

    if (filterApplicantStatus) {
      const localizedFilterValue = applicantStatusOptions.find(
        (option) => option.id === filterApplicantStatus.id
      );
      setFilterApplicantStatus(localizedFilterValue);
    }

    if (filterGender) {
      const localizedFilterValue = genderOptions.find(
        (option) => option.id === filterGender.id
      );
      setFilterGender(localizedFilterValue);
    }

    if (filterAge) {
      const localizedFilterValue = ageOptions.find(
        (option) => option.id === filterAge.id
      );
      setFilterAge(localizedFilterValue);
    }

    if (filterWeight) {
      const localizedFilterValue = weightOptions.find(
        (option) => option.id === filterWeight.id
      );
      setFilterWeight(localizedFilterValue);
    }

    if (filterHeight) {
      const localizedFilterValue = heightOptions.find(
        (option) => option.id === filterHeight.id
      );
      setFilterHeight(localizedFilterValue);
    }
  }, [lang]);

  useEffect(() => {
    if (filterRole) {
      const localizedFilterValue = roles.find(
        (option) => option.id === filterRole.id
      );
      setFilterRole(localizedFilterValue);
    }
  }, [lang, roles]);

  useEffect(() => {
    if (filterLanguages) {
      const localizedFilterValue = languages.find(
        (option) => option.id === filterLanguages.id
      );
      setFilterLanguages(localizedFilterValue);
    }
  }, [lang, languages]);

  useEffect(() => {
    if (filterDisabilities) {
      const localizedFilterValue = disabilities.find(
        (option) => option.id === filterDisabilities.id
      );
      setFilterDisabilities(localizedFilterValue);
    }
  }, [lang, disabilities]);

  useEffect(() => {
    if (filterChronicDiseases) {
      const localizedFilterValue = chronicDiseases.find(
        (option) => option.id === filterChronicDiseases.id
      );
      setFilterChronicDiseases(localizedFilterValue);
    }
  }, [lang, chronicDiseases]);

  useEffect(() => {
    if (filterCities) {
      const localizedFilterValue = cities.find(
        (option) => option.id === filterCities.id
      );
      setFilterCities(localizedFilterValue);
    }
  }, [lang, cities]);

  useEffect(() => {
    getApplicants(
      page,
      searchValue,
      filterProfileStatus,
      filterApplicantStatus,
      filterGender,
      filterAge,
      filterWeight,
      filterHeight,
      filterRole,
      filterLanguages,
      filterChronicDiseases,
      filterDisabilities,
      filterCities
    );
  }, [page]);

  useEffect(() => {
    if (
      (searchValue.length >= 3 || searchValue.length === 0) &&
      !textContainOnlySpaces(searchValue)
    ) {
      setPage(1);
      getApplicants(
        1,
        searchValue,
        filterProfileStatus,
        filterApplicantStatus,
        filterGender,
        filterAge,
        filterWeight,
        filterHeight,
        filterRole,
        filterLanguages,
        filterChronicDiseases,
        filterDisabilities,
        filterCities
      );
    }
  }, [searchValue]);

  useEffect(() => {
    getApplicants(
      page,
      searchValue,
      filterProfileStatus,
      filterApplicantStatus,
      filterGender,
      filterAge,
      filterWeight,
      filterHeight,
      filterRole,
      filterLanguages,
      filterChronicDiseases,
      filterDisabilities,
      filterCities
    );
  }, [lang, note]);

  useEffect(() => {
    dispatch(getLocationsRequest());
  }, [lang, note]);

  const handleDeleteLocation = (id) => {
    dispatch(deleteLocationRequest({ id }));
    history.push(ROUTE_PATHS["applicants"]);
    setIsNoteModalOpen(false);
  };
  useEffect(() => {
    setPage(1);
    getApplicants(
      1,
      searchValue,
      filterProfileStatus,
      filterApplicantStatus,
      filterGender,
      filterAge,
      filterWeight,
      filterHeight,
      filterRole,
      filterLanguages,
      filterChronicDiseases,
      filterDisabilities,
      filterCities
    );
  }, [
    filterProfileStatus,
    filterApplicantStatus,
    filterGender,
    filterAge,
    filterWeight,
    filterHeight,
    filterRole,
    filterLanguages,
    filterChronicDiseases,
    filterDisabilities,
    filterCities,
  ]);

  const getApplicants = (
    pageNo,
    search,
    profileStatus,
    isAssignedToEvent,
    gender,
    age,
    weight,
    height,
    roleId,
    languageId,
    diseaseId,
    disabilityId,
    city
  ) => {
    dispatch(
      getApplicantsRequest({
        page: pageNo,
        items: 10,
        name: search,
        gender: gender?.value,
        profile_status: profileStatus?.value,
        is_assigned_to_event: isAssignedToEvent?.value,
        role_id: roleId?.id,
        language_id: languageId?.id,
        disability_id: disabilityId?.id || null,
        without_disabilities: disabilityId?.id === 0 || null,
        without_chronic_diseases: diseaseId?.id === 0 || null,
        chronic_disease_id: diseaseId?.id || null,
        min_age: age?.value[0],
        max_age: age?.value[1],
        min_height: height?.value[0],
        max_height: height?.value[1],
        min_weight: weight?.value[0],
        max_weight: weight?.value[1],
        order_by_first_name_en: lang === "en" ? "asc" : null,
        order_by_first_name_ar: lang === "ar" ? "asc" : null,
        city_id: city?.id,
      })
    );
  };
  const viewApplicantHandler = (event) => {
    const applicant = list.find((applicant) => applicant.id === event.row.id);
    if (
      applicant?.profile_status === applicantsProfileStatus.profileCompleted ||
      applicant?.profile_status ===
        applicantsProfileStatus.basicProfileCompleted
    ) {
      history.push(ROUTE_PATHS.viewApplicant.replace(":id", event.row.id));
    }
  };

  const renderApplicantList = () => {
    if (!isLoading && !list?.length) {
      return (
        <>
          <div className="bg-white rounded-4 d-flex justify-content-center align-items-center flex-column my-4 pt-5">
            <EmptyState
              description={applicants.listApplicants.noDataDescription}
              showActionButton={false}
              imgSrc={applicantPlaceholder}
            />
          </div>
        </>
      );
    } else {
      return (
        <>
          <div>
            <DynamicTable
              clickCellHandler={viewApplicantHandler}
              rows={rows}
              columns={columns}
              checkboxSelection={false}
              getRowClassName={(params) => {
                if (
                  !params.row.name.isBlocked &&
                  params.row.profileStatusValue !==
                    applicantsProfileStatus.verified
                ) {
                  return "row-hover";
                }
              }}
            />
          </div>
          <div className="d-flex justify-content-center py-3 mt-4">
            {meta.pages > 1 && (
              <Pagination
                count={meta.pages}
                page={meta.currentPage || page}
                handleChange={(event, page) => {
                  setPage(page);
                }}
                defaultPage={1}
              />
            )}
          </div>
        </>
      );
    }
  };

  return (
    <div className="applicants-page-container">
      <div className="page-title-margin">
        <Breadcrumbs
          list={[
            {
              path: "",
              label: applicants.applicantsList,
            },
          ]}
          BreadCrumbHasBackIcon={false}
        />
      </div>
      <Filters
        searchInput={{
          placeholder: applicants.listApplicants.searchPlaceHolder,
          name: "search",
          value: searchValue,
          onChange: (value) => setSearchValue(value),
        }}
        filters={[
          {
            type: "filter",
            name: "profileStatus",
            placeholder: applicants.listApplicants.profileStatus,
            value: filterProfileStatus,
            options: profileStatusOptions,
            onChange: (value) => setFilterProfileStatus(value),
          },
          {
            type: "filter",
            name: "applicantStatus",
            placeholder: applicants.listApplicants.applicantStatus,
            value: filterApplicantStatus,
            options: applicantStatusOptions,
            onChange: (value) => setFilterApplicantStatus(value),
          },
          {
            type: "filter",
            name: "role",
            placeholder: applicants.listApplicants.role,
            value: filterRole,
            options: roles,
            onChange: (value) => setFilterRole(value),
          },
          {
            type: "filter",
            name: "gender",
            placeholder: applicants.listApplicants.gender,
            value: filterGender,
            options: genderOptions,
            onChange: (value) => setFilterGender(value),
          },
          {
            type: "filter",
            name: "height",
            placeholder: applicants.listApplicants.height,
            value: filterHeight,
            options: heightOptions,
            onChange: (value) => setFilterHeight(value),
          },
          {
            type: "filter",
            name: "weight",
            placeholder: applicants.listApplicants.weight,
            value: filterWeight,
            options: weightOptions,
            onChange: (value) => setFilterWeight(value),
          },
          {
            type: "filter",
            name: "disability",
            placeholder: applicants.listApplicants.disability,
            value: filterDisabilities,
            options: disabilities
              ? [
                  ...disabilities,
                  { id: 0, name: applicants.listApplicants.noDisability },
                ]
              : [],
            onChange: (value) => setFilterDisabilities(value),
          },
          {
            type: "filter",
            name: "diseases",
            placeholder: applicants.listApplicants.diseases,
            value: filterChronicDiseases,
            options: chronicDiseases
              ? [
                  ...chronicDiseases,
                  { id: 0, name: applicants.listApplicants.noDisease },
                ]
              : [],
            onChange: (value) => setFilterChronicDiseases(value),
          },
          {
            type: "filter",
            name: "age",
            placeholder: applicants.listApplicants.age,
            value: filterAge,
            options: ageOptions,
            onChange: (value) => setFilterAge(value),
          },
          {
            type: "filter",
            name: "language",
            placeholder: applicants.listApplicants.language,
            value: filterLanguages,
            options: languages,
            onChange: (value) => setFilterLanguages(value),
          },
          {
            type: "filter",
            name: "city",
            placeholder: applicants.listApplicants.cityOfResidence,
            value: filterCities,
            options: cities,
            onChange: (value) => setFilterCities(value),
          },
        ]}
      />
      <div className="d-flex flex-column justify-content-between">
        {renderApplicantList()}
        <Modal
          open={isNoteModalOpen}
          title={shared.addYourNote}
          handleClose={handleModalClose}
          cancelBtnTxt={shared.cancel}
          handleCancel={handleModalClose}
          confirmBtnTxt={shared.submit}
          handleConfirm={handleSubmit}
          isDisabled={validate}
          content={
            <Note
              validate={validate}
              setValidate={setValidate}
              handleSubmit={handleSubmit}
              handleDelete={(id) => handleDeleteLocation(id)}
              setOtherValue={setOtherValue}
              otherValue={otherValue}
              selectValue={selectValue}
              setSelectValue={setSelectValue}
              location={location}
              handleCancel={handleModalClose}
            />
          }
        />
      </div>
    </div>
  );
};

export default Applicants;
